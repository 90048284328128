import React from "react"
import { Link } from "gatsby"

import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <div className="layout-container home-page">
    <SEO title="Home" />
    <div className="bg-blur"></div>
    <div className="landing">
      <div className="hero">
        <h1>Welcome to your new home.</h1>
        {/* <p>Some descriptive text for SEO and to tell people what we do</p> */}
        <p>
          <span>
            Taylor-Hill Properties are located in a convenient Abilene location, close to all your favorite modern amenities. Only minutes from Abilene's premier dining, entertainment and retail destinations. All properties are located on the north east side, giving easy access to both Abilene Christian University and Hardin Simmons University, and less than a 20 minute commute to Dyess Air-Force Base.
          </span>
          <br />
          <span>
            We offer a selection of upgraded one, two, and three-bedroom apartment homes throughout our 7 locations. Look at floorplans online, or to get a more personalized service, <Link to="/contact" className="cta-highlight">contact us</Link>.
          </span>
        </p>
        
        <div className="home-links">
          <Link to="/apartments" className="btn btn-primary">View Apartments</Link>
          <a 
            href="https://taylorhillproperties.appfolio.com/listings" 
            target="_blank" 
            className="btn">
              Apply Now
          </a>
        </div>
      </div>
    </div>


    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
  </div>
)

export default IndexPage